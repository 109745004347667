import { Language } from '@lightningjs/sdk'

import {
  ENTITLEMENT,
  MPARTICLE_DEFAULT_ATTR_VALUE,
  PROGRAMMING_TYPES,
  TOKEN_TYPE,
} from '../../../../constants'
import { StreamAuthorizationType } from '../../../../pages/Player/StreamLoader/request'

export const getResumeAttributes = ({ resumeTime }: any) => {
  const isResume = !!resumeTime
  return {
    Resume: isResume ? 'True' : 'False',
    'Resume Time': isResume ? resumeTime : MPARTICLE_DEFAULT_ATTR_VALUE,
  }
}

type VodAttributeParams = {
  authType?: StreamAuthorizationType
  programmingType?: any
  brand?: { title: string }
  brandDisplayTitle?: string
  seriesShortTitle?: string
  movieShortTitle?: string
  seasonNumber?: any
  mpxGuid?: any
  pid?: any
  locked?: any
  genre?: string
  category?: any
  secondaryGenre?: string
  videoTitle?: string
  secondaryTitle?: string
  episodeNumber?: any
  sport?: string
  league?: string
  language?: string
  programTitle?: string
  title?: string
  tokenType?: string
}

export const getTokenType = (authType?: StreamAuthorizationType) => {
  if (authType === StreamAuthorizationType.MVPD) {
    return TOKEN_TYPE.MVPD
  }
  return MPARTICLE_DEFAULT_ATTR_VALUE
}
const getVodAttributes = (
  params: VodAttributeParams = {},
  ccLanguage: any,
  previousVideo: any,
  previousVideoType: any,
  analytics: any = {}
) => {
  if (!params) return {}
  const isMovie = params?.programmingType === PROGRAMMING_TYPES.MOVIE
  return {
    Brand: params.brand ? params.brand.title : params.brandDisplayTitle,
    Show: params.seriesShortTitle || params.movieShortTitle || params.programTitle,
    Season: params.seasonNumber,
    'Video ID': params.mpxGuid || params.pid,
    'Video Type': params.programmingType,
    Entitlement: params.locked ? ENTITLEMENT.ENTITLED : ENTITLEMENT.FREE,
    Genre: params.genre,
    Category: params.category,
    'Secondary Genre': params.secondaryGenre,
    ...(!isMovie
      ? {
          'Episode Title': params.videoTitle || params.secondaryTitle || params.title,
          'Episode Number': params.episodeNumber,
        }
      : { 'Episode Title': params.movieShortTitle }),
    'CC Language': ccLanguage !== 'off' && ccLanguage,
    Sport: params.sport || analytics.sport || MPARTICLE_DEFAULT_ATTR_VALUE,
    League: params.league || analytics.league || MPARTICLE_DEFAULT_ATTR_VALUE,
    Language: analytics.language || params.language || Language.get(),
    'Previous Video': previousVideo,
    'Previous Video Type': previousVideoType,
    'Token Type': params.tokenType || getTokenType(params.authType),
  }
}

export default getVodAttributes
